<route>
{
  "meta": {
    "permission": [
      "equipements.view_equipement"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col cols="12" sm="3">
        <ValidationProvider vid="name" :name="$t('name')" v-slot="{ errors }">
          <v-text-field
            v-model="form.name"
            outlined
            :error-messages="errors[0]"
            :label="$t('name')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="production_department"
          :name="$t('department')"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="form.production_department"
            outlined
            :error-messages="errors[0]"
            :label="$t('department')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
    <i-table
      :title="$t('equipmentReport')"
      :headers="headers"
      app="equipements.equipement"
      api="equipment"
      :opt="{
        name: this.form.name,
        production_department: this.form.production_department
      }"
      dontEdit
      dontCreate
      dontSearch
    >
      <template v-slot:item.bought_date_and_rent_date="{ item }">
        <span
          v-text="
            item.bought_date != null
              ? dateFormat(item.bought_date)
              : dateFormat(item.rent_start_date) +
                ' / ' +
                dateFormat(item.rent_end_date)
          "
        ></span>
      </template>
      <template v-slot:extraToolbar>
        <v-divider class="mx-4" inset vertical></v-divider>
        <i-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-file-excel"
          :title="$t('download', { value: $t('equipmentReport') })"
          color="text-dark"
          @click="getEquipmentsExcel()"
        >
        </i-btn>
      </template>
    </i-table>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: '',
        production_department: ''
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('model'),
          value: 'modelName'
        },
        {
          text: this.$t('serial'),
          value: 'serial'
        },
        {
          text: this.$t('mode'),
          value: 'mode'
        },
        {
          text: this.$t('bought_date_and_rent_date'),
          value: 'bought_date_and_rent_date'
        },
        {
          text: this.$tc('department', 1),
          value: 'production_departament.name'
        }
      ]
    }
  },
  methods: {
    getEquipmentsExcel() {
      this.downloadFile({
        api: 'equipment',
        method: 'excel',
        filename: 'Equipments',
        action: 'download',
        ext: 'xlsx',
        opt: {
          pk: 1,
          params:
            '?name=' +
            this.form.name +
            '&production_department=' +
            this.form.production_department
        }
      })
    }
  }
}
</script>
