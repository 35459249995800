var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('ValidationProvider',{attrs:{"vid":"name","name":_vm.$t('name')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"secondary--text",attrs:{"outlined":"","error-messages":errors[0],"label":_vm.$t('name')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('ValidationProvider',{attrs:{"vid":"production_department","name":_vm.$t('department')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"secondary--text",attrs:{"outlined":"","error-messages":errors[0],"label":_vm.$t('department')},model:{value:(_vm.form.production_department),callback:function ($$v) {_vm.$set(_vm.form, "production_department", $$v)},expression:"form.production_department"}})]}}])})],1)],1),_c('i-table',{attrs:{"title":_vm.$t('equipmentReport'),"headers":_vm.headers,"app":"equipements.equipement","api":"equipment","opt":{
      name: this.form.name,
      production_department: this.form.production_department
    },"dontEdit":"","dontCreate":"","dontSearch":""},scopedSlots:_vm._u([{key:"item.bought_date_and_rent_date",fn:function({ item }){return [_c('span',{domProps:{"textContent":_vm._s(
          item.bought_date != null
            ? _vm.dateFormat(item.bought_date)
            : _vm.dateFormat(item.rent_start_date) +
              ' / ' +
              _vm.dateFormat(item.rent_end_date)
        )}})]}},{key:"extraToolbar",fn:function(){return [_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('i-btn',{attrs:{"text":"","small":_vm.$vuetify.breakpoint.smAndDown,"icon":"fa-file-excel","title":_vm.$t('download', { value: _vm.$t('equipmentReport') }),"color":"text-dark"},on:{"click":function($event){return _vm.getEquipmentsExcel()}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }